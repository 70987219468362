import create from 'zustand'
import { buildUrl } from '../../utils/buildUrl';
import { BaseImage } from "../../components/Common/BaseImage";
import { Tabs } from "../../components/Common/Tabs";
import BaseVideo from "../../components/Common/BaseVideo";

export type interactive = {
  id: number
  title: string
  content: {
    type: "tabs" | "headline" | "text" | "image" | "video"
    data: BaseImage | Tabs | string | BaseVideo
  }[]
}

export type Story = {
  name: string
  urlName: string
  content: {
    start: {
      title: string
      text: string
      image: BaseImage
    },
    interactives: interactive[],
    share: {
      title: string
      text: string
    },
    summary: {
      title: string
      image: BaseImage
    },
  },
  assets: {
    modelUri: string
    fallbackModelUri: string
    leadUrl: string
    qrUri: string
  }
}

export type Stories = Map<string, Story>

type StoryService = {
  stories: Stories
  selectedStoryUrlName: string | null
  init: () => Promise<Stories>
  dispose: () => void
  selectStory: (urlName: string | null) => Story | null
  getSelectedStory: () => Story | null
};

const STORIES_JSON_URI: string = process.env.PUBLIC_URL + '/res/stories.json'

const storyService = create<StoryService>((set, get) => {
  const loadStories = async (uri): Promise<Stories> => {
    return new Promise<Stories>(async (resolve, reject) => {
      if(!uri) reject('storyService::loadStories(): Invalid uri!')

      const response = await fetch(uri).catch(error => {
        console.error("storyService::loadStories(): Failed to load stories from uri:", uri, " with error =", error)
        reject(`storyService::loadStories(): Failed fetching uri = ${uri}`)
      })

      if(!response) reject('Invalid response!')

      // @ts-expect-error
      const json = await response.json()

      const { stories } : {stories: Array<Story>} = json
      set({stories: new Map<string, Story>(stories.map(story => {
        const urlName = buildUrl(story.name)
        return [urlName, {...story, urlName}]
      }))})

      console.log("storyService::loadStories(): Loaded stories =", get().stories)

      resolve(get().stories)
    })
  }

  const getStoryByUrlName = (urlName: string) => {
    if(!urlName) return null
    urlName = urlName.trimEnd()

    if(!get().stories?.has(urlName)) return null

    return get().stories.get(urlName)
  }

  return {
    stories: null,
    selectedStoryUrlName: null,
    init: async () => {
      return loadStories(STORIES_JSON_URI)
    },
    dispose: () => {
      set({
        selectedStoryUrlName: null
      })
    },
    selectStory: (urlName) => {
      const story: Story | null = getStoryByUrlName(urlName)

      if(!story) {
        get().dispose()
        return null;
      }

      set({selectedStoryUrlName: urlName})
      return story
    },
    getSelectedStory: () => {
      return getStoryByUrlName(get().selectedStoryUrlName)
    }
  }
})

export default storyService
