import { SkeletonUtils } from "three-stdlib"
import { Object3D, AnimationMixer, Clock, AnimationAction, AnimationClip, MeshBasicMaterial, sRGBEncoding } from 'three'
import { useEffect, useState } from 'react'
import sceneService from "../../../services/sceneService"
import videoService from "../../../services/videoService"

interface AnimateObject {
  object: Object3D
  animations: any
}

const AnimateObject: Object3D<AnimateObject> = ({object}) => {
  const [skeleton, setSkeleton] = useState<Object3D | null>(null)
  const [animationMixer, setAnimationMixer] = useState<AnimationMixer | null>(null)
  const [animationAction, setAnimationAction] = useState<AnimationAction | null>(null)
  const [clock] = useState<Clock>(new Clock())

  const loadAnimations = () => {
    console.log('load animations')
    const skeletonName: string = skeleton.name.toLowerCase()
    console.log(skeletonName)
    console.log(sceneService.getState().animations)

    const animationClips: AnimationClip[] = sceneService.getState().animations?.filter(clip => clip.name.toLowerCase().includes(skeletonName))

    if(!animationClips[0]) {
      console.warn(`AnimateObject::loadAnimations(): Failed to load animation clips for skeleton with name = ${skeleton.name}`)
      return
    }

    const animationClip: AnimationClip = animationClips[0]

    const animationAction: any = animationMixer.clipAction(animationClip, skeleton)
    animationAction.weight = 1.0

    animationAction.startAt(0)
    animationAction.play()

    setAnimationAction(animationAction)

   window.removeEventListener('customPlayVideo', playAnimation)
  }

  const addStartListener = () => {
    window.addEventListener('customPlayVideo', playAnimation)
    window.addEventListener('customInterruptVideo', stopAnimation)


  }

  const playAnimation = () => {
    console.log('play animation')
    loadAnimations()
  }

  const stopAnimation = () => {
    if(animationMixer){
      console.log('stop animation')
      animationMixer.stopAllAction()

    }
  }


  useEffect(() => {
    object.traverse(child => {
      if(!child.isMesh) return

      child.frustumCulled = false
    })

    setSkeleton(SkeletonUtils.clone(object))
  }, [])

  useEffect(() => {
    if(!skeleton) return

    skeleton.children.forEach((child) => {
      if(child.type === "SkinnedMesh") {
        child.material = new MeshBasicMaterial({map: child.material.map})
        child.material.map.encoding = sRGBEncoding
      }
    })

    setAnimationMixer(new AnimationMixer(skeleton))
  }, [skeleton])

  useEffect(() => {
    if(!animationMixer) return
    //loadAnimations()
    addStartListener()
  }, [animationMixer])

  useEffect(() => {
    if(!animationAction) return
    requestAnimationFrame(update)
  }, [animationAction])

  const update = () => {
    // ToDo(Eric) Cancel update after component is unmounted.

    animationMixer.update(animationMixer.timeScale * clock.getDelta())
    requestAnimationFrame(update)
  }

  return skeleton ? <primitive object={skeleton}/> : <></>
}

export default AnimateObject