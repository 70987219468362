import {ScrollWrapper, ContentWrapper, ButtonWrapper, ImageWrapper } from './styles';
import { Button } from '../../components/Common/Button';
import storyService, { Story } from '../../services/storyService';
import {Headline, HeadlineXl, Link, Text} from "../../styles/typography";
import initService from '../../services/initService';
import { StyledTabs } from '../../components/Common/Tabs/styles';
import BaseImage from '../../components/Common/BaseImage';
import {  useNavigate } from 'react-router-dom';

const Summary = () => {
    const navigate = useNavigate()

  const isInitialized = initService(state => state.initialized)
  if(!isInitialized) return <></>

  const story: Story | null = storyService.getState().getSelectedStory()

  const handleBackToContact = () => {
    navigate(`/contact/bayer-ar`)


 };

  return (
          <ScrollWrapper>
            <ContentWrapper>
            <ImageWrapper>
                    <BaseImage
                    src={process.env.PUBLIC_URL + "/res/tex/one_logo.png"}
                    width={512}
                    height={350}
                />
             </ImageWrapper>
             <HeadlineXl>Summary</HeadlineXl>
             <Text>
             <b>We aim to optimally support you in</b>  <br /><br />
             Delivering world class innovations, including the initial idea sparking <br /><br />
             Driving operational performance via honest and actionable outside-in perspectives and best practices <br /><br />
             Generating sustainable and valued impact
             </Text>
               
            </ContentWrapper>

            { <ButtonWrapper>
                  <Button onClick={handleBackToContact} text={'Back to contact'} />
              </ButtonWrapper>
            }
          </ScrollWrapper>
  );
};

export default Summary;
