import create from 'zustand'
import sceneService from '../sceneService';


type VIDEOSTATE = "BUFFERING" | "READYTOPLAY" | "PLAYING" | "FINISHED"

type VideoService = {
  mode: VIDEOSTATE
  isPlaying: boolean
  setMode: (mode: VIDEOSTATE) => void
  startVideoPreload: () => void
  setVideoReady: () => boolean
  setVideoFinished: () => void
  startVideoTimeline: () => void
  stopActiveVideo: () => void
  reset: () => void
};

const videoService = create<VideoService>((set, get) => {
  const playEvent = new CustomEvent('customPlayVideo', { });
  const interruptVideoEvent = new CustomEvent('customInterruptVideo', { });

  return {
    mode: "BUFFERING",
    isPlaying: false,
    startVideoPreload: () => {
      console.log('start video preload')
      set({
        mode: "BUFFERING",
        isPlaying: false
      })
    },
    setVideoReady: () => {
      console.log('video ready to play')
      set({
        mode: "READYTOPLAY",
        isPlaying: false
      })  

      return true
    },
    startVideoTimeline: () => {
      console.log('video starts playing')
      window.dispatchEvent(playEvent)

      set({
        mode: "PLAYING",
        isPlaying: true
      })

    },
    setVideoFinished: () => {
      console.log('video finished')

      //Go to next page
      setTimeout(() => {window.location.href = window.location.href.replace("ar/","contact/")}, 5000)

      set({
        mode: "FINISHED",
        isPlaying: false
      })  
    },
    stopActiveVideo: () => {
      console.log('interrupt active')

      window.dispatchEvent(interruptVideoEvent)

      set({
        mode: "READYTOPLAY",
        isPlaying: false
      })  
    },
    setMode: (mode) => {  
      set({
        mode: mode
      }) 
    }, 
    reset: () => {
      set({
        mode: "BUFFERING",
        isPlaying: false
      })
    }
  }
})

export default videoService