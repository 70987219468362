import { FC, useEffect, useState } from 'react'
import interactionService from '../../services/interactionService';
import { Story } from '../../services/storyService';
import { Button } from '../Common/Button';
import {StyledArFooter, StyledInteractionProgress, StyledPlaceComponent} from './styles';
import {theme} from "../../styles/theme";
import sceneService from '../../services/sceneService';
import { reset } from '../../utils/reset';
import videoService from '../../services/videoService';

interface ArFooter {
  story: Story
}

declare let XR8: any;

const ArFooter: FC<ArFooter> = ({story}) => {
  const backUrl: string = `/${story.urlName}`
  const shareUrl: string = `/contact/${story.urlName}`
  const interactionMode = interactionService(state => state.mode)
  const videoState = videoService(state => state.mode)

  const required = interactionService(state => state.required)
  const [component, setComponent] = useState(<></>)

  const handlePlaceClick = event => {
    if (interactionService.getState().mode === "PLACING"){

       // delay mode switch in order to avoid click event falltrough to platform
      setTimeout(() => interactionService.getState().placeInteraction() , 100)
    }
  };

  const handleRecenterClick = event => {
    if (interactionService.getState().mode === "PLACED"){
      interactionService.getState().reset()    
      XR8.XrController.recenter()
    }
  };


  const handlePlayClick = event => {   
    console.log('handlePlayclick')

      if (videoService.getState().mode === "READYTOPLAY"){
        videoService.getState().startVideoTimeline()
        }
  };

  useEffect(() => {
    //const finishedRequiredInteractions = required.filter(entry => entry.interacted).length
    //const totalRequiredInteractions = required.length


    switch (interactionMode) {
      case "PLACING": {
        setComponent(
            <StyledPlaceComponent>
              <Button url={backUrl} iconUrl={process.env.PUBLIC_URL + "/res/img/arrow_left.svg"} backgroundColor={theme.colors.grey} />
              <Button onClick={handlePlaceClick} text={'Place object in scene'} />
            </StyledPlaceComponent>
        )
        break
      }
      case "PLACED": {
        setComponent(
          <StyledPlaceComponent>
             <Button onClick={handleRecenterClick} iconUrl={process.env.PUBLIC_URL + "/res/img/ar_place.svg"} backgroundColor={theme.colors.grey} />
             {videoState === "BUFFERING" && <Button text={'Loading video...'}  backgroundColor={theme.colors.grey} />}
             {videoState === "READYTOPLAY" && <Button onClick={handlePlayClick} text={'Play message'}  backgroundColor={theme.colors.orange} />}
         </StyledPlaceComponent>
        )
        break
      }
      default: {
        setComponent(<></>)
        break
      }
    }

  }, [interactionMode, required, videoState])

  return <StyledArFooter>{component}</StyledArFooter>
}

export default ArFooter
