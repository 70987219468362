import styled, {css} from 'styled-components';
import {StyledButton} from "../../components/Common/Button/styles";
import InspectBaseLayout from "../../components/Common/BaseLayout";
import {theme} from "../../styles/theme"
import {device} from "../../styles/mediaQueries"
import {StyledTabs} from "../../components/Common/Tabs/styles";
import {HeadlineXl, Text, Headline} from "../../styles/typography";
import BaseImage from '../../components/Common/BaseImage';

export const ScrollWrapper = styled(InspectBaseLayout)`
  position: relative;
  max-height: 100%;
  overflow-y: scroll;
`

export const ButtonWrapper = styled.div`
  position: fixed;
  z-index: 10;
  bottom: 2.4rem;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - var(--page-padding) - var(--page-padding));
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${device.tablet} {
    bottom: var(--page-padding);
  }

  @media ${device.desktop} {
    bottom: 2.4rem;
    justify-content: right;

  }
  
  ${StyledButton} {
    //Glow 
    &:before {
      content: "";
      position: absolute;
      top: 70%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 97%;
      height: 60%;
      box-shadow: rgb(0 0 0 / 25%) 0px 4px 4px;
      filter: blur(13px);
      background-color: ${theme.colors.orange};
      z-index: -1;

    }

    @media ${device.desktop} {
      width: 300px;
      height: 60px;

      ${Headline} {
        font-size: 2rem;
  
      }

    }

  }
`

export const ContentWrapper = styled.aside`
  margin-top: 0rem;
  margin-bottom: 8rem;
  display: grid;
  gap: 2rem;
  
  ${StyledTabs} {
    margin-top: 3.8rem;
  }

  ${HeadlineXl} {
    margin-top: 1.8rem;
  }

  ${Headline} {
    margin-top: 1.8rem;
  }
  

  @media ${device.tablet} {
    margin-top: 0rem;
    margin-bottom: 10rem;
    gap: 4rem;
    
    ${Text},
    ${HeadlineXl} {
      max-width: 64rem;

    }
  }

  @media ${device.desktop} {
    ${Text}{
      max-width: 100%;
      font-size: 2rem;

    }
    ${HeadlineXl} {
      max-width: 100%;
      font-size: 3rem;
    }

    ${Headline} {
      margin-top: 1.8rem;
      font-size: 2rem;

    }

  }
  
`

export const ImageWrapper = styled.div`
  margin-top: 1.8rem;

  max-width: 53rem;
  height: auto;
`
