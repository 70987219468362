import * as THREE from 'three'
import { useLayoutEffect, useRef } from 'react'
import glsl from 'babel-plugin-glsl/macro'
import { extend, useFrame, useLoader } from '@react-three/fiber'

export class ChromakeyMaterial extends THREE.ShaderMaterial {
  constructor(videoTexture) {
    super({
      uniforms: {
        tex: {
          type: 'sampler2D', value: videoTexture
        },
        keyColor: {
          type: 'vec3', value: new THREE.Color(0x00ff000)
        },
        texWidth: {
          type: 'float', value: 1920
        },
        texHeight: {
          type: 'float', value: 1080
        },
        similarity: {
          type: 'float', value: 0.40
        },
        smoothness: {
          type: 'float', value: 0.07
       },
        spill: {
          type: 'float', value: 0.1
        }
      },

      vertexShader: `
          varying vec2 vUv;

          void main() {
            vUv = uv;
            gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
          }
      `,

      fragmentShader: glsl`
              uniform sampler2D tex;
              uniform float texWidth;
              uniform float texHeight;

              uniform vec3 keyColor;
              uniform float similarity;
              uniform float smoothness;
              uniform float spill;
              
              varying vec2 vUv;

              // From https://github.com/libretro/glsl-shaders/blob/master/nnedi3/shaders/rgb-to-yuv.glsl
              vec2 RGBtoUV(vec3 rgb) {
                return vec2(
                  rgb.r * -0.169 + rgb.g * -0.331 + rgb.b *  0.5    + 0.5,
                  rgb.r *  0.5   + rgb.g * -0.419 + rgb.b * -0.081  + 0.5
                );
              }

              vec4 ProcessChromaKey(vec2 texCoord) {
                vec4 rgba = texture2D(tex, texCoord);
                float chromaDist = distance(RGBtoUV(texture2D(tex, texCoord).rgb), RGBtoUV(keyColor));

                float baseMask = chromaDist - similarity;
                float fullMask = pow(clamp(baseMask / smoothness, 0., 1.), 1.5);
                rgba.a = fullMask;

                float spillVal = pow(clamp(baseMask / spill, 0., 1.), 1.5);
                float desat = clamp(rgba.r * 0.2126 + rgba.g * 0.7152 + rgba.b * 0.0722, 0., 1.);
                rgba.rgb = mix(vec3(desat, desat, desat), rgba.rgb, spillVal);

                return rgba;
              }

              void main(void) {
                vec2 texCoord = vUv;
                gl_FragColor = ProcessChromaKey(texCoord);
              }
                
      `
    })

  }
}

extend({ ChromakeyMaterial })
