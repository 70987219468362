import create from 'zustand'

type RequiredInteraction = {
  id: number
  interacted: boolean
}

type InteractionMode = "PLACING" | "PLACED" | "PLAYING"

type InteractionService = {
  mode: InteractionMode
  selected: number
  required: Array<RequiredInteraction>
  setInteraction: (id: number, interacted?: boolean) => boolean
  registerRequiredInteraction: (id: number) => void
  setSelected: (id: number | null) => void
  placeInteraction: () => void
  reset: () => void
};

const interactionService = create<InteractionService>((set, get) => {
  const placeEvent = new CustomEvent('objectPlaced', { });
  const repositionEvent = new CustomEvent('objectReposition', { });

  return {
    mode: "PLACING",
    selected: null,
    required: new Array<RequiredInteraction>(),

    setInteraction: (id, interacted = true) => {
      const entry: RequiredInteraction = get().required.find(entry => entry.id === id)

      if(!entry) return false

      entry.interacted = interacted

      if(interacted){
        set({mode: "PLACED"})
        window.dispatchEvent(placeEvent)
      }

      set({required: get().required})  

      return true
    },
    placeInteraction: () => {
      set({mode: "PLACED"})
      window.dispatchEvent(placeEvent)    },
    registerRequiredInteraction: (id) => {
      get().required.push({id, interacted: false})
    },
    setSelected: (id) => {
      set({selected: id})
    },
    reset: () => {
      set({
        mode: "PLACING",
        selected: null,
        required: new Array<RequiredInteraction>(),
      })

      window.dispatchEvent(repositionEvent)

    }
  }
})

export default interactionService