import create from 'zustand'
import storyService, { Story } from '../storyService';
import {LoadingFactory} from '../../components/arCanvas/loading-module/loading-module';
import customThreejsPipelineModule from '../../components/arCanvas/customThreejsPipelineModule';
import customPipelineModule from '../../components/arCanvas/customPipelineModule';
import debugService from '../debugService';
 
declare const window: any;
declare let XR8: any;
declare let LandingPage: any;

type ArService = {
  active: boolean
  started: boolean
  loading: any
  init: () => void
  start: (canvas) => void
}



const addCustomElementsToLandingPage = () =>{
  document.querySelector('.landing8-container').parentElement.setAttribute('id', 'custom-landing-container-root');
  document.querySelector('.landing8-container').setAttribute('id', 'custom-landing-container');
  document.querySelector('.landing8-centered-container').setAttribute('id', 'custom-centered-container');

  document.querySelector('.landing8-media-view').setAttribute('id', 'custom-model-viewer-info');
 

  //Headline
  const divLogo = document.createElement('div');
  divLogo.className = 'custom-container-logo';
  divLogo.id = 'custom-container-logo';

  divLogo.innerHTML = 
      '<img id="custom-container-logo" class="custom-container-logo" src="' + process.env.PUBLIC_URL + '/res/img/PricewaterhouseCoopers_Logo.png" />'
  ;
  document.querySelector('.landing8-centered-container').insertBefore(divLogo,  document.querySelector('.landing8-media-view'));

  //Links
  const divBody = document.createElement('div');
  divBody.className = 'custom-linkwrapper';
  divBody.id = 'custom-linkwrapper';

  const FooterString = '<div id="custom-linkwrapper-container" class="custom-linkwrapper-container"><a href=' + process.env.PUBLIC_URL + "/impressum" + ' target="_self" rel="noopener noreferrer">Impressum</a><a href=' + process.env.PUBLIC_URL + "/privacy-policy" + ' target="_self" rel="noopener noreferrer">Datenschutzerklärung</a></div>';

  divBody.innerHTML = FooterString;
  document.querySelector('.landing8-centered-container').insertBefore(divBody,  document.querySelector('.landing8-media-view').nextSibling);


}

const arService = create<ArService>((set, get) => {
  const landingPage = () => {
    if(XR8.XrDevice.isDeviceBrowserCompatible()){
      return
    }     

    const pathnameParts: Array<string> = window.location.pathname.split('/')
    let story: Story | null = null

    if(pathnameParts.length > 1) {
      const storyUrl: string = pathnameParts[pathnameParts.length - 1]
      story = storyService.getState().selectStory(storyUrl)
    }

    if(!story)
      story = storyService.getState().stories.values().next().value

    if(!story)
      console.error('arService::landingPage(): No fallback story found!')  

    const mediaSrc: string = `${story.assets.fallbackModelUri}`
    const url: string = `${window.location.origin}${process.env.PUBLIC_URL}/`

    XR8.addCameraPipelineModules([
      LandingPage.pipelineModule(),
    ])

    LandingPage.configure({
      mediaSrc,
      mediaAutoplay: false,	
      promptPrefix: 'Nutzen Sie Ihr Smartphone um die AR Experience zu öffnen. Scannen Sie dazu den QR Code oder besuchen Sie ',
      promptSuffix: '',
      textColor: '#000000',
      font: "'Helvetica', sans-serif",
      backgroundBlur: '1',
      backgroundColor: 'linear-gradient(#FFFFFF, #FFFFFF)',
      backgroundSrc: '',
      sceneEnvMap: 'none',
      sceneOrbitIdle: 'spin',
      sceneOrbitInteraction: 'drag',
      url
    })

    XR8.run({ canvas:  window.mainCanvas })

    addCustomElementsToLandingPage();
  }

  const load = (canvas) => {
    const customThree = customThreejsPipelineModule() 

    XR8.addCameraPipelineModules([
      XR8.XrController.pipelineModule(), // Enables SLAM tracking.
      XR8.GlTextureRenderer.pipelineModule(), // Draws the camera feed.
      get().loading.pipelineModule(), // Manages the loading screen on startup
      customThree, // Custom pipeline modules.
      customPipelineModule(customThree)
      //XRExtras.Stats.pipelineModule()
    ]);

    if (XR8.XrDevice.isDeviceBrowserCompatible()) {
      arService.setState({started: true})
    }

    
    XR8.XrController.configure({
      enableLighting: false,
      enableWorldPoints: true,
      disableWorldTracking: false,
    });

    XR8.run({ canvas });
  }
  const screenlog = () => {
    window.XRExtras.DebugWebViews.enableLogToScreen()
  }

  return {
    active: false,
    started: false,
    loading: LoadingFactory(),
    init: () => {
      window.XR8 ? landingPage() : window.addEventListener('xrloaded', landingPage)
      if(debugService.getState().debugEnabled)
        window.XRExtras ? screenlog() : window.addEventListener('xrextrasloaded', screenlog)
    },
    start: (canvas) => {
      if(get().started) return

      const startAfterLoad = () => {
        get().loading.showLoading({ onxrloaded: load(canvas) })
      }
      window.XR8 ? startAfterLoad() : window.addEventListener('xrloaded', startAfterLoad)
    },
  }
})

export default arService